import {
  Login as LoginComponent,
  Auth as MediastoreAuth,
} from "@cleeng/mediastore-sdk";
import { AuthContext } from "Context/AuthContext";
import React, { useContext } from "react";

const Login = (props) => {
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);

  React.useEffect(() => {
    MediastoreAuth.isLogged();
  }, [isAuthenticated]);
  return (
    <div>
      {MediastoreAuth.isLogged() ? (
        <>
          <h2 data-cy="loggedInMessage">You are already logged in.</h2>
        </>
      ) : (
        <>
          <h1>Login</h1>
          <div id="Sub-Component-Login">
            <LoginComponent
              onSuccess={setIsAuthenticated}
              onPasswordResetClick={() =>
                (window.location.href = "https://test.com/PasswordReset")
              }
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Login;
