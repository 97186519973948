import { Auth as MediastoreAuth } from "@cleeng/mediastore-sdk";
import { AuthContext } from "Context/AuthContext";
import Cookies from "js-cookie";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../App.css";
import Dropdown from "./Dropdown";
import logo from "./premiumplus.png";

const NavigationBar = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const [dropdown, setDropdown] = useState(false);
  const firstRender = useRef(true);

  useEffect(() => {
    // Skip if not first render
    if (!firstRender.current) {
      return;
    }
    cleanupCookieOnFirstLoad();
    // Mark first render complete
    firstRender.current = false;

    // Create script element
    const script = document.createElement("script");

    // Configure script properties
    script.src = "/zdauth-broadcaster.js";
    script.async = true;

    // Add load handlers to log success or failure
    script.onload = () => {
      console.log("ZD Auth script loaded successfully");
    };
    script.onerror = () => {
      console.error("Failed to load ZD Auth script");
    };

    // Append script to document body to load it
    document.body.appendChild(script);

    // Cleanup function to remove script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty deps array - only run once on mount

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    setDropdown(false);
  };

  const cleanupCookieOnFirstLoad = () => {
    if (
      Cookies.get("_cleeng_zd") &&
      !localStorage.getItem("CLEENG_REFRESH_TOKEN") &&
      !localStorage.getItem("CLEENG_AUTH_TOKEN")
    ) {
      console.log("Cleaning up cookie on first load...");
      Cookies.remove("_cleeng_zd");
    }
  };

  const handleLogout = () => {
    MediastoreAuth.logout();
    window.location.reload(false);
    localStorage.removeItem("CLEENG_REFRESH_TOKEN");
    localStorage.removeItem("CLEENG_AUTH_TOKEN");
    // Remove Zendesk auth cookie on logout
    Cookies.remove("_cleeng_zd");
  };

  const handleClear = () => {
    localStorage.removeItem("CLEENG_REFRESH_TOKEN");
    localStorage.removeItem("CLEENG_AUTH_TOKEN");
    window.location.reload(false);
    // Remove Zendesk auth cookie on logout
    Cookies.remove("_cleeng_zd");
  };

  React.useEffect(() => {
    MediastoreAuth.isLogged();
  }, [isAuthenticated]);

  return (
    <div className="AppAuth">
      <header className="main-menu menu">
        <p className="menu-link">
          <img src={logo} alt="logo" height="36px" />
        </p>

        <ul className="menu">
          <Link
            className="nav-links"
            style={{ cursor: "pointer" }}
            data-cleeng="helpcenter"
            to="https://cleengsupport.premiumplus.dev"
            target="_blank"
          >
            Help Center
          </Link>
          <li onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <Link
              to="/allLowerComponents"
              className="nav-links"
              data-cy="allLowerComponents"
            >
              allLowerComponents
            </Link>
            {dropdown && <Dropdown />}
          </li>
          <li>
            <Link className="nav-links" to="/checkout">
              CheckOut
            </Link>
          </li>
          <li>
            <Link className="nav-links" to="/myaccount">
              MyAccount
            </Link>
          </li>

          <>
            <li className="button-adjustment">
              <button
                className="button-red"
                onClick={handleLogout}
                data-cy="logoutButton"
              >
                Logout
              </button>
              <button
                className="button-orange"
                onClick={handleClear}
                data-cy="logoutButton"
              >
                Clear
              </button>
            </li>
          </>
        </ul>
      </header>
    </div>
  );
};
export default NavigationBar;
